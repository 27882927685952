.forms {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background: #ffffffcf;
    transition: .5s;
    opacity: 0;
    z-index: -1;
}

.forms.active {
    transition: .5s;
    opacity: 1;
    z-index: 9999999999999;
}

.form-wrap {
    width: 350px;
    margin: 0 auto;
    padding: 1rem;
    background: white;
    box-shadow: 4px 4px 2px 1px #0000006e;
}

.close {
    position: relative;
    top: -5rem;
    left: 2rem;
}

.form-error {
    border: 2px solid red !important;
}

.form-error-label {
    color: red;
    font-weight: 700;
}

@media screen and (max-width: 380px) {
    .form-wrap {
        width: 290px;
    }
}