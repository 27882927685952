body {
    font-family: 'Roboto', sans-serif;
}

main {
    padding-top: 65px;
}
.w-40 {
    width: 768px;
}

.blue {
    color: #283ab6;
}

.btn-outline-primary {
    color: #283ab6;
    border-color: #283ab6;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #283ab6;
    border-color: #283ab6;
}

@media screen and (max-width: 768px){
    .w-40 {
        width: 100%;
    }
}