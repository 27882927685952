header {
    padding-top: 5px;
    padding-bottom: 5px;
    background: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0px 3px 2px 2px #00000021;
    z-index: 999999;
}

.logo {
    width: 12rem;
    margin-right: auto;
}

.header_title {
    font-weight: 600;
    margin-right: auto;
    margin-left: 5rem;
    font-size: 1.3rem;
    color: #283ab6;
}

.header_phone {
    text-decoration: none;
    font-weight: 600;
    font-size: 1.5rem;
    color: #283ab6;
}

.else-toogle {
    width: 40px;
}

.phone_header {
    width: 25px;
    margin-right: 5px;
}

@media screen and (max-width: 991px) {
    .header_title {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .header_title {
        display: none;
        margin-right: 0;
    }

    .header_phone {
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 580px) {
    .header_phone {
        font-size: 1rem;
    }
}

@media screen and (max-width: 500px) {
    .header_phone {
        display: none;
    }
}