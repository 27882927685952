.area-home-page {
    padding-top: 75px;
}
.area-images {
    width: 13rem;
    height: 10rem;
    background-size: cover;
    transition: .5s;
}

.area-images:hover {
    cursor: pointer;
    transition: .5s;
    box-shadow: 5px 6px 3px 0px #283ab6b3;
}

.area-item {
    display: block;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}


.area-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.area-text {
    width: 13rem;
    margin: 0 auto;
    height: 4rem;
    font-size: 18px;
    line-height: 25px;
}

.area1 {
    background-image: url("./images/1.JPG");
}

.area2 {
    background-image: url("./images/2.JPG");
}

.area3 {
    background-image: url("./images/3.JPG");
}

.area4 {
    background-image: url("./images/4.JPG");
}

.area5 {
    background-image: url("./images/5.JPG");
}

.area6 {
    background-image: url("images/6.JPG");
}

.area7 {
    background-image: url("./images/7.JPG");
}

.area8 {
    background-image: url("./images/8.JPG");
}

.area9 {
    background-image: url("./images/9.JPG");
}

.area10 {
    background-image: url("./images/10.JPG");
}

.area11 {
    background-image: url("./images/11.JPG");
}

.area12 {
    background-image: url("./images/12.JPG");
}

.area13 {
    background-image: url("./images/13.JPG");
}

.area14 {
    background-image: url("./images/14.JPG");
}

.area15 {
    background-image: url("./images/15.JPG");
}

.area16 {
    background-image: url("./images/16.JPG");
}

.area17 {
    background-image: url("./images/17.JPG");
}

.area18 {
    background-image: url("./images/18.JPG");
}

.area-caorusel {
    display: none;
}

@media screen and (max-width: 768px) {
    .area-wrap {
        display: none;
    }
    .area-caorusel {
        display: block;
    }
}
@media screen and (max-width: 470px) {
    .area-images {
        width: 14rem;
        height: 10rem;
    }

    .area-text {
        width: 13rem;
        margin: 0 auto;
        line-height: 20px;
    }
}