nav {
    background: #f2f2ff;
    margin-top: 5px;
    position: fixed;
    height: 100%;
    top: -5px;
    right: 0;
    display: grid;
    width: 18rem;
    padding-top: 5rem;
    padding-bottom: 35rem;
    transition: .5s;
    transform: translate(105%);
    z-index: 999999999;
    box-shadow: -4px 0px 2px #0000001f;
}

nav.active {
    transform: translate(0%);
}

.nav-item {
    margin-left: auto;
    margin-right: auto;
    padding-top: 3px;
    font-size: 16px;
    text-decoration: none;
    text-align: left;
    min-width: 9rem;
    transition: .1s;
    height: 2.5rem;
    color: #283ab6;
    margin-bottom: 2rem;
    display: block;
}

.nav-item.active {
    border-bottom: 5px solid;
}

.nav-item:hover {
    color: #283ab6;
    border-bottom: 5px solid;
    transition: .1s;
}

.nav_toogle {
    position: fixed;
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid #283ab6;
    border-radius: 5px;
    padding: 5px;
    transition: .5s;
    padding-top: 7px;
    top: 14px;
    z-index: 999999;
    right: 16px;
    background: white;
}

.nav_toogle:hover {
    cursor: pointer;
    transition: .5s;
    opacity: .5;
}

.burger {
    width: 100%;
    height: 4px;
    background: #283ab6;
    border-radius: 5px;
    margin-bottom: 6px;
}

.nav_toogle_close {
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid #283ab6;
    right: 1rem;
    border-radius: 5px;
    padding: 5px;
    transition: .5s;
    padding-top: 8px;
    top: 13px;
}

.nav_toogle_close:hover {
    cursor: pointer;
    transition: .5s;
    opacity: .5;
}

.burger.l {
    transform: rotate(
            45deg);
    top: 17px;
    left: 4px;
    position: absolute;
    width: 80%;
}

.burger.r {
    transform: rotate(
            -45deg);
    top: 17px;
    left: 4px;
    position: absolute;
    width: 80%;
}
