.preference-home-page {
    background: #f3f7fa;
    padding-top: 5rem;
}

.pref-item {
    width: 39rem;
    padding: 3rem 1rem 3rem 1rem;
    margin-left: 1rem;
    background: white;
    margin-top: 2rem;
    border-radius: .5rem;
    box-shadow: 0px -2px 2px 0px #00000052 inset;
}

.pref-text {
    font-size: 18px;
}

.form-wrap-pref {
    display: flex;
    align-items: center;
    padding: 1rem;
}

.right-form-preference {
    width: 35rem;
}

.light-blue {
    font-size: 3rem;
    font-weight: bolder;
    color: #b6c1dd;
    text-shadow: 0px 2px 2px #757a88;
}

@media screen and (max-width: 1399px) {
    .pref-item {
        width: 33rem;
    }
}

@media screen and (max-width: 1199px) {
    .pref-item {
        width: 100%;
    }
}

@media screen and (max-width: 991px) {
    .form-wrap-pref {
        display: block;
        width: max-content;
        margin: 0 auto;
    }
}

@media screen and (max-width: 768px) {
    .form-wrap-pref {
        width: 100%;
    }

    .pref-item {
        display: grid;
    }

    .card768 {
        display: grid !important;
    }

    .ms-5-768 {
        margin-left: 0 !important;
    }

    .light-blue {
        font-size: 2rem;
    }

    .right-form-preference {
        width: 100%;
    }
}

@media screen and (max-width: 450px) {

    .pref-text {
        width: 100%;
    }
}