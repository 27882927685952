.service-home-page {
    background: #f3f7fa;
    padding-top: 75px;
}

.img-service {
    width: 7rem;
    padding: 1rem;
}

.service-text {
    height: 12rem;
    text-align: left;
    margin-left: 1rem;
}

.service-item-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.contact-wrap {
    margin-left: 5rem;
}

.service-item {
    width: 26rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1rem 1rem 0rem 0rem;
    padding: .5rem;
    background: white;
    box-shadow: 0px 4px 3px 1px #0000000a;
    margin-bottom: 2rem;
}

@media screen and (max-width: 1399px) {
    iframe {
        margin: 0 auto;
        order: 2;
        width: 100%;
    }

    .contact-wrap {
        text-align: center;
        margin: 0 auto;
        order: 1;
    }
}

@media screen and (max-width: 991px) {
    .service-item-wrap {
        display: grid;
    }

    .service-text {
        height: auto;
    }
}

@media screen and (max-width: 576px) {
    .service_buttons {
        width: 100% !important;
    }
}

@media screen and (max-width: 460px) {
    .service-item {
        width: 100% ;
    }
}

