#admin-panel {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 9999;
}

.admin_box {
    border: none;
    background: transparent;
}

.card {
    overflow: hidden;
    height: 55px;
}

.active.card {
    height: auto;
}