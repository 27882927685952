.about-home-page {
    width: 100%;
    background-image: url("comand.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 65px;
}

.item-about {
    width: 55rem;
    padding: 3rem;
    margin-left: auto;
}

@media screen and (max-width: 1366px) {
    .about-home-page {
        background: none;
    }

    .item-about {
        margin: 0 auto;
    }
}

@media screen and (max-width: 920px) {
    .item-about {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .item-about {
        padding: 1rem;
    }
}