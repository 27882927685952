.contact-home-page {
    background: rgb(40, 58, 182);
    background: -moz-linear-gradient(0deg, rgba(40, 58, 182, 1) 0%, rgba(0, 133, 255, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(40, 58, 182, 1) 0%, rgba(0, 133, 255, 1) 100%);
    background: linear-gradient(0deg, rgba(40, 58, 182, 1) 0%, rgba(0, 133, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#283ab6", endColorstr="#0085ff", GradientType=1);
    padding-top: 75px;
}

.contact-item {
    margin-left: auto;
}

.contact-img {
    height: 15px;
    margin-right: 10px;
    margin-bottom: 3px;
}

.contact_a:hover {
    text-shadow: 2px 2px 2px black;
}

@media screen and (max-width: 520px) {
    .form-wrap-pref {
        width: 100%;
    }
}