@keyframes pulse {
    from {transform: scale(1);}
    50% {transform: scale(1.1);}
    to {transform: scale(1);}
}

.loader-wrap {
    background-image: url("images/loader.png");
    background-size: cover;
    background-position: top left;
}
.animate {
    width: 40%;
    animation: pulse 5s infinite ease-in-out;
}

@media screen and (max-width: 768px) {
    .animate {
        width: 60%;
    }
}