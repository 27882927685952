.home-page {
    width: 100%;
    background-image: url("home.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.blue-back {
    background: rgba(40, 58, 182, 0.87);
    display: flex;
    align-items: center;
    padding-top: 9rem;
    padding-bottom: 9rem;
    padding-left: 5rem;
}

.arrow-home {
    width: 2rem;
    margin-right: .5rem;
}

.blue-btns {
    background: rgb(40, 58, 182);
    background: -moz-linear-gradient(90deg, rgba(40, 58, 182, 1) 0%, rgba(0, 133, 255, 1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(40, 58, 182, 1) 0%, rgba(0, 133, 255, 1) 100%);
    background: linear-gradient(90deg, rgba(40, 58, 182, 1) 0%, rgba(0, 133, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#283ab6", endColorstr="#0085ff", GradientType=1);
}

.blue-btns:hover {
    color: white;
    background: rgba(0, 133, 255, 1);
}

@media screen and (max-width: 768px) {
    .blue-back {
        padding: 1rem;
        width: 100%;
    }
}

@media screen and (max-width: 420px) {
    .arrow-home {
        width: 1rem;
        margin-right: 0.25rem;
        padding-top: 0.5rem;
    }
}